/* default, light mode styling */
body {
  background-color: #fff;
  color: #333;
}

a {
  color: #003f7f;
  text-decoration: underline;
}

/* if user switches the system settings to dark mode */
/* this media query will be applied */
@media (prefers-color-scheme: dark) {
  body {
    background-color: #333;
    color: #fff;
  }

  a {
    color: #b6cadf;
  }
}